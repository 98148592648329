import { Box, Fab } from '@material-ui/core'
import { Close, Pencil } from 'mdi-material-ui'
import React from 'react'
import { Category } from './Category'
import { Section as SectionInterface } from './config/data'
import { Text } from './Text'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SectionProps extends SectionInterface {
    close: () => void
}

export const Section: React.FC<SectionProps> = ({
    categories = [],
    close,
    Icon,
    name
}) => {
    return (
        <Box p={4} mb={8} position='relative'>
            <Box alignItems='center' display='flex' mt={2} width='100%'>
                <Box mr={3} mt={0.5}>
                    <Icon color='primary' fontSize={'large'} />
                </Box>
                <Text
                    boxProps={{ flexGrow: 1, width: '100%' }}
                    color='primary'
                    variant='h2'
                >
                    {name}
                </Text>
            </Box>
            <Box justifyContent='center' display='flex' width='100%'>
                <Box width={['100%', 600, 800]}>
                    <Box flexGrow={1} width='100%'>
                        {categories.map((category) => (
                            <Category key={category.name} {...category} />
                        ))}
                    </Box>
                </Box>
            </Box>

            <Box
                alignItems='center'
                bottom={0}
                display='flex'
                justifyContent='flex-end'
                p={3}
                ml={-4}
                position='fixed'
                style={{ backdropFilter: 'blur(20px)' }}
                width='100%'
            >
                <Fab
                    color='primary'
                    size='large'
                    style={{ boxShadow: 'none', marginRight: 24 }}
                    variant='extended'
                >
                    <Box mr={1}>
                        <Pencil />
                    </Box>
                    Edit
                </Fab>
                <Fab
                    onClick={close}
                    size='large'
                    style={{ boxShadow: 'none' }}
                    variant='round'
                >
                    <Close />
                </Fab>
            </Box>
        </Box>
    )
}
