import { Category } from 'config/data'
import React from 'react'
import { Text } from '../../Text'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HeadingOptionProps extends Category {}

export const HeadingOption: React.FC<HeadingOptionProps> = ({ name }) => {
    return (
        <Text boxProps={{ mt: 8, mb: 3, color: 'primary.main' }} variant='h3'>
            {name}
        </Text>
    )
}
