import { Box, MenuItem, Select } from '@material-ui/core'
import { Category } from 'config/data'
import React from 'react'
import { Text } from '../../Text'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SelectOptionProps extends Category {}

export const SelectOption: React.FC<SelectOptionProps> = ({
    name,
    categories = []
}) => {
    console.log(categories)

    return (
        <Box alignItems='center' display='flex'>
            <Box flexGrow={1}>
                <Text boxProps={{ mx: 1, my: 2 }} color='textSecondary'>
                    {name}
                </Text>
            </Box>
            <Box ml={2}>
                <Select
                    margin='none'
                    variant='outlined'
                    value={categories[0]?.name}
                    //   onChange={handleChange}
                >
                    {categories?.map((category) => (
                        <MenuItem key={category.name} value={category.name}>
                            {category.name}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </Box>
    )
}
