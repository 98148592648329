import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import { theme } from './config/theme'

ReactDOM.render(
    // <React.StrictMode>
    <BrowserRouter>
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </MuiThemeProvider>
    </BrowserRouter>,
    // </React.StrictMode>,
    document.getElementById('root')
)
