import { Box, BoxProps, Typography, TypographyProps } from '@material-ui/core'
import React from 'react'

export interface TextProps extends TypographyProps {
    boxProps?: BoxProps
}

export const Text: React.FC<TextProps> = ({ boxProps, ...typographyProps }) => {
    return (
        <Box {...boxProps}>
            <Typography {...typographyProps} />
        </Box>
    )
}
