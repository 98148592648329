import { RadioGroup } from '@material-ui/core'
import { Category } from 'config/data'
import React from 'react'
import { HeadingOption } from './Heading'
import { RadioOption } from './Radio'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HeadingRadioOptionProps extends Category {}

export const HeadingRadioOption: React.FC<HeadingRadioOptionProps> = (
    heading
) => {
    return (
        <>
            <HeadingOption {...heading} />
            <RadioGroup>
                {heading.categories?.map((category) => (
                    <RadioOption {...category} />
                ))}
            </RadioGroup>
        </>
    )
}
