import { Dialog as MuiDialog, Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import React from 'react'
import { useHistory } from 'react-router'
import { Section as SectionInterface } from './config/data'
import { Section } from './Section'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />
})

export interface DialogProps {
    open: boolean
    section: SectionInterface
}

export const Dialog: React.FC<DialogProps> = ({ open, section }) => {
    const { push } = useHistory()

    const close = () => {
        push('/')
    }

    return (
        <MuiDialog
            fullScreen
            open={open}
            onClose={close}
            TransitionComponent={Transition}
        >
            <Section {...section} close={close} />
        </MuiDialog>
    )
}
