import { Box, Grid } from '@material-ui/core'
import React from 'react'
import { Card } from './Card'
import { sections } from './config/data'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MainMenuProps {}

export const MainMenu: React.FC<MainMenuProps> = () => {
    return (
        <Box>
            <Grid container spacing={2}>
                {sections.map((section) => (
                    <Grid item key={section.name} xs={6} md={4}>
                        <Card {...section} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}
