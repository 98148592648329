import { Box } from '@material-ui/core'
import React from 'react'
import { useLocation } from 'react-router'
import { sections } from './config/data'
import { Dialog } from './Dialog'
import { Layout } from './Layout'
// import logo from './logo.png'
import { MainMenu } from './MainMenu'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppProps {}

export const App: React.FC<AppProps> = () => {
    const { pathname } = useLocation()

    return (
        <>
            <Layout>
                {/* <img alt='Logo' src={logo} style={{ width: 50 }} /> */}
                <Box mt={2} width='100%'>
                    <MainMenu />
                </Box>
            </Layout>
            {sections.map((section) => (
                <Dialog
                    key={section.name}
                    open={section.name.toLowerCase() === pathname.split('/')[1]}
                    section={section}
                />
            ))}
        </>
    )
}
