import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

export const theme = responsiveFontSizes(
    createMuiTheme({
        typography: {
            fontFamily: 'Poppins'
        },
        palette: {
            type: 'dark',
            primary: { main: '#00b4f5' }
        }
        // shape: { borderRadius: 0 }
    })
)
