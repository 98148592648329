import { Box } from '@material-ui/core'
import { Category } from 'config/data'
import React from 'react'
import { CheckboxOption } from './Checkbox'
import { HeadingOption } from './Heading'
import { HeadingRadioOption } from './HeadingRadio'
import { InputOption } from './Input'
import { RadioOption } from './Radio'
import { SelectOption } from './Select'
import { SwitchOption } from './Switch'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OptionProps extends Category {}

export const Option: React.FC<OptionProps> = (option) => {
    const options = {
        checkbox: <CheckboxOption {...option} />,
        heading: <HeadingOption {...option} />,
        headingRadio: <HeadingRadioOption {...option} />,
        input: <InputOption {...option} />,
        radio: <RadioOption {...option} />,
        select: <SelectOption {...option} />,
        'select.option': null,
        switch: <SwitchOption {...option} />
    }
    return <Box>{options[option.type]}</Box>
}
