import { SvgIconTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import {
    AccessPoint,
    Check,
    Close,
    Cog,
    Devices,
    Lan,
    Lock,
    RouterWireless,
    Security,
    Web,
    Wifi,
    WifiLockOpen
} from 'mdi-material-ui'

export type Icon =
    | OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
    | React.FunctionComponent<
          React.SVGProps<SVGSVGElement> & {
              title?: string | undefined
          }
      >

export interface Section {
    categories?: Category['categories']
    Icon: Icon
    name: string
    statuses: Status[]
}

export interface Status {
    Icon: Icon
    name: string
    value: string
}

export interface Category {
    name: string
    categories?: Category[]
    props?: any
    type:
        | 'checkbox'
        | 'heading'
        | 'headingRadio'
        | 'input'
        | 'radio'
        | 'select'
        | 'select.option'
        | 'switch'
}

export const sections: Section[] = [
    {
        Icon: Web,
        name: 'Internet',
        statuses: [{ Icon: Check, name: 'Status', value: 'Good' }]
    },
    {
        Icon: Wifi,
        name: 'Wireless',
        statuses: [
            { Icon: AccessPoint, name: 'SSID', value: 'MyWifi' },
            { Icon: Lock, name: 'Password', value: 'P@ssw0rd' }
        ],
        categories: [
            {
                name: 'WiFi Mode',
                type: 'heading',
                categories: [
                    {
                        name: 'Enable AX',
                        type: 'checkbox',
                        props: {}
                    },
                    {
                        name: 'Enable 5G',
                        type: 'checkbox'
                    },
                    {
                        name: 'Enable 2.4G',
                        type: 'checkbox'
                    }
                ]
            },
            {
                name: 'Wireless Settings',
                type: 'heading',
                categories: [
                    {
                        name: 'Name (SSID)',
                        type: 'input'
                    },
                    {
                        name: '2.4GHz Channel',
                        type: 'select',
                        categories: [
                            { name: 'Auto', type: 'select.option' },
                            { name: '01', type: 'select.option' },
                            { name: '02', type: 'select.option' },
                            { name: '03', type: 'select.option' },
                            { name: '04', type: 'select.option' },
                            { name: '05', type: 'select.option' },
                            { name: '06', type: 'select.option' },
                            { name: '07', type: 'select.option' },
                            { name: '08', type: 'select.option' },
                            { name: '09', type: 'select.option' },
                            { name: '10', type: 'select.option' },
                            { name: '11', type: 'select.option' }
                        ]
                    },
                    {
                        name: '5GHz Channel',
                        type: 'select',
                        categories: [
                            { name: '36', type: 'select.option' },
                            { name: '40', type: 'select.option' },
                            { name: '44', type: 'select.option' },
                            { name: '48', type: 'select.option' }
                        ]
                    }
                ]
            },
            {
                name: 'Security Options',
                categories: [
                    {
                        name: 'None',
                        type: 'radio'
                    },
                    {
                        name: 'WPA2-PSK [AES]',
                        type: 'radio'
                    },
                    {
                        name: 'WPA-PSK [TKIP] + WPA2-PSK [AES]',
                        type: 'radio'
                    },
                    {
                        name: 'WPA3-Personal [SAE]',
                        type: 'radio'
                    }
                ],
                type: 'headingRadio'
            }
        ]
    },
    {
        Icon: Devices,
        name: 'Devices',
        statuses: [
            { Icon: RouterWireless, name: 'Satellites', value: '1' },
            { Icon: Lan, name: 'Devices', value: '33' }
        ]
    },
    {
        categories: [
            {
                name: 'Wireless Settings',
                type: 'heading',
                categories: [
                    {
                        name: 'Enable Guest Network',
                        type: 'switch'
                    },
                    {
                        name: 'Guest Network Name (SSID)',
                        type: 'input'
                    }
                ]
            },
            {
                name: 'Security Options',
                categories: [
                    {
                        name: 'None',
                        type: 'radio'
                    },
                    {
                        name: 'WPA2-PSK [AES]',
                        type: 'radio'
                    },
                    {
                        name: 'WPA-PSK [TKIP] + WPA2-PSK [AES]',
                        type: 'radio'
                    },
                    {
                        name: 'WPA3-Personal [SAE]',
                        type: 'radio'
                    }
                ],
                type: 'headingRadio'
            }
        ],
        Icon: WifiLockOpen,
        name: 'Guest Network',
        statuses: [{ Icon: Close, name: 'Status', value: 'Not Enabled' }]
    },
    {
        Icon: Security,
        name: 'Security',
        statuses: [{ Icon: Check, name: 'Status', value: 'Enabled' }]
    },
    {
        Icon: Cog,
        name: 'Settings',
        statuses: []
    }
]
