import React from 'react'
import { Option } from './components/options/Option'
import { Category as CategoryInterface } from './config/data'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CategoryProps extends CategoryInterface {}

export const Category: React.FC<CategoryProps> = (category) => {
    const { categories: options = [], ...rest } = category
    return (
        <>
            <Option
                {...rest}
                categories={category.type === 'select' ? options : undefined}
            />
            {options.map((option) => {
                return <Category key={option.name} {...option} />
            })}
        </>
    )
}
