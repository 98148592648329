import { Box, TextField } from '@material-ui/core'
import { Category } from 'config/data'
import React from 'react'
import { Text } from '../../Text'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InputOptionProps extends Category {}

export const InputOption: React.FC<InputOptionProps> = ({ name }) => {
    return (
        <Box alignItems='center' display='flex'>
            <Box flexGrow={1}>
                <Text boxProps={{ mx: 1, my: 2 }} color='textSecondary'>
                    {name}
                </Text>
            </Box>
            <Box ml={2}>
                <TextField
                    color='primary'
                    margin='none'
                    size='small'
                    variant='outlined'
                />
            </Box>
        </Box>
    )
}
