import { Box } from '@material-ui/core'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LayoutProps {}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <Box
            alignItems='center'
            display='flex'
            justifyContent='center'
            // minHeight='100vh'
            px={4}
            py={2}
            width='100vw'
        >
            <Box
                alignItems='center'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='100%'
            >
                {children}
            </Box>
        </Box>
    )
}
