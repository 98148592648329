import {
    Avatar,
    Box,
    Card as MuiCard,
    Tooltip,
    useTheme
} from '@material-ui/core'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Section } from './config/data'
import { Text } from './Text'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CardProps extends Section {}

export const Card: React.FC<CardProps> = ({
    name,
    Icon,
    statuses: subsections
}) => {
    const [hovered, setHovered] = useState(false)
    const { push } = useHistory()
    const theme = useTheme()
    return (
        <MuiCard elevation={0}>
            <Box
                alignItems='center'
                color='primary'
                display='table'
                flexDirection='column'
                py={6}
                onClick={() => push(`/${name.toLowerCase()}`)}
                onMouseEnter={() => {
                    setHovered(true)
                }}
                onMouseLeave={() => {
                    setHovered(false)
                }}
                style={{
                    background: hovered
                        ? theme.palette.primary.main
                        : undefined,

                    cursor: 'pointer',
                    transition: 'all 0.5s ease',
                    transform: `scale(${hovered ? 1.1 : 1})`
                }}
                width='100%'
            >
                <Box display='table-cell' height='100%' width='100%'>
                    <Box
                        alignItems='center'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        height='100%'
                        width='100%'
                    >
                        <Box
                            pt={!subsections.length ? 3 : 0}
                            style={{
                                color: hovered
                                    ? theme.palette.background.paper
                                    : theme.palette.primary.main
                            }}
                        >
                            <Icon />
                        </Box>
                        <Text
                            align='center'
                            boxProps={{
                                pb: 4,
                                mt: 1,
                                mx: 1,
                                width: '100%'
                            }}
                            variant='h5'
                            style={{
                                color: hovered
                                    ? theme.palette.background.paper
                                    : theme.palette.primary.main,
                                wordWrap: 'break-word'
                            }}
                        >
                            {name}
                        </Text>
                        <Box
                            alignItems='center'
                            display='flex'
                            justifyContent='center'
                        >
                            {subsections.map(({ Icon, name, value }, index) => (
                                <Box
                                    key={name}
                                    mr={
                                        subsections.length - 1 > index ? 0.5 : 0
                                    }
                                >
                                    <Avatar
                                        style={{
                                            background: hovered
                                                ? theme.palette.background.paper
                                                : theme.palette.primary.main,
                                            border: 'none',
                                            color: hovered
                                                ? theme.palette.primary.main
                                                : theme.palette.background
                                                      .paper,
                                            height: theme.spacing(3),
                                            width: theme.spacing(3)
                                        }}
                                    >
                                        <Tooltip
                                            style={{}}
                                            title={`${name}: ${value}`}
                                        >
                                            <Icon
                                                style={{
                                                    height: theme.spacing(2),
                                                    width: theme.spacing(2)
                                                }}
                                            />
                                        </Tooltip>
                                    </Avatar>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MuiCard>
    )
}

// import {
//     Box,
//     Card,
//     CardContent,
//     CardHeader,
//     Typography,
//     useTheme,
// } from '@material-ui/core'
// import { format } from 'date-fns'
// import numeral from 'numeral'
// import React, { useState } from 'react'
// import { useHistory } from 'react-router'
// import { modules } from '../config/modules'
// import { Opportunity as OpportunityInterface } from './interfaces'
// import { OpportunityStatusIcon } from './opportunities-status-icon'

// // eslint-disable-next-line @typescript-eslint/no-empty-interface
// export interface OpportunityCardProps extends OpportunityInterface {}

// export const OpportunityCard: React.FC<OpportunityCardProps> = ({
//     createdDate,
//     name,
//     _id,
//     margin,
//     status,
// }) => {
//     const [hovered, setHovered] = useState(false)
//     const { push } = useHistory()
//     const theme = useTheme()
//     return (
//         <Card
//             elevation={0}
//             onClick={() => push(`${modules.home.path}${_id}`)}
//             onMouseEnter={() => {
//                 setHovered(true)
//             }}
//             onMouseLeave={() => {
//                 setHovered(false)
//             }}
//             style={{
//                 background: hovered ? theme.palette.primary.main : undefined,
//                 color: hovered ? theme.palette.primary.contrastText : undefined,
//                 border: `1px solid ${theme.palette.divider}`,
//                 cursor: 'pointer',
//                 position: 'relative',
//                 transition: 'all 0.5s ease',
//                 transform: `scale(${hovered ? 1.05 : 1})`,
//                 zIndex: hovered ? 1 : undefined,
//             }}
//         >
//             <CardHeader
//                 avatar={<OpportunityStatusIcon status={status} />}
//                 title={name}
//                 subheader={format(new Date(createdDate), 'dd/MM/y')}
//                 subheaderTypographyProps={{
//                     style: {
//                         color: hovered
//                             ? theme.palette.primary.contrastText
//                             : undefined,
//                         transition: 'all 0.5s ease',
//                         opacity: hovered ? 0.7 : undefined,
//                     },
//                 }}
//             />
//             <CardContent>
//                 <Box display='flex' alignItems='center'>
//                     <Typography variant='h4'>€</Typography>
//                     <Typography variant='h2'>
//                         {numeral(margin).format(
//                             margin > 10000 ? '0,0a' : '0,0.0a'
//                         )}
//                     </Typography>
//                 </Box>
//             </CardContent>
//         </Card>
//     )
// }
