import { Box, Switch } from '@material-ui/core'
import { Category } from 'config/data'
import React from 'react'
import { Text } from '../../Text'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CheckboxOptionProps extends Category {}

export const SwitchOption: React.FC<CheckboxOptionProps> = ({ name }) => {
    return (
        <Box alignItems='center' display='flex'>
            <Box flexGrow={1}>
                <Text boxProps={{ mx: 1, my: 2 }} color='textSecondary'>
                    {name}
                </Text>
            </Box>
            <Box ml={2}>
                <Switch color='primary' />
            </Box>
        </Box>
    )
}
